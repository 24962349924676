import { Injectable } from '@angular/core';
import { SpinnerDialog } from '@awesome-cordova-plugins/spinner-dialog/ngx';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx/';
// import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { ActionSheetController, AlertController, LoadingController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Logger, LoggingService } from 'ionic-logging-service';
import { Observable, Subject, Subscriber } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StringularService } from '../../pipes/stringular.pipe';
import { EnvironmentService } from '../environment/environment.service';
import { PlatformService } from '../platform/platform.service';
import { NetworkService } from '../network/network.service';
import { Broadcaster } from '../events/broadcaster.class';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  private loadingIndicator = null;
  public isShowingLoader = false;
  public isInternetAccessAvailable = false;
  private unSubscribe: Subject<boolean> = new Subject<boolean>();
  public isCordova: boolean = this.platformService.isCordova;
  private logger: Logger;

  constructor(
    private actionSheetCtrl: ActionSheetController,
    private alertCtrl: AlertController,
    private broadcaster: Broadcaster,
    private environmentService: EnvironmentService,
    private loadingCtrl: LoadingController,
    private loggingService: LoggingService,
    private networkService: NetworkService,
    private platform: Platform,
    private platformService: PlatformService,
    private spinnerDialog: SpinnerDialog,
    // private splashScreen: SplashScreen,
    private stringularService: StringularService,
    private translateService: TranslateService
  ) {
    this.logger = loggingService.getLogger("[DialogService]");
    const methodName = "ctor";
    this.logger.entry(methodName);

    this.networkService.isInternetAvailable
    .subscribe((isInternetAccessAvailable: boolean) => {
      this.isInternetAccessAvailable = isInternetAccessAvailable;
    });
  }

  public showAlertDialog(message: string): void {
    this.logger.info('showAlertDialog()');
    let alertOkButtonText: string = '';
    this.translateService.get('Close')
    .pipe(takeUntil(this.unSubscribe))
    .subscribe((value: string) => {
      alertOkButtonText = value;
      this.unSubscribe.next();
      this.unSubscribe.complete();
    });
    let alert = this.alertCtrl.create({
      header: 'Printix App',
      subHeader: message,
      buttons: [alertOkButtonText]
    }).then(alert => alert.present());
  }

  public showTranslatedAlertDialog(message: string, title?: string): Observable<void> {
    this.logger.info('showTranslatedAlertDialog()');
    let closeButton: string = 'Close';
    return new Observable<void>((observer: Subscriber<void>) => {
      this.translateService.get([closeButton, message, title])
      .pipe(takeUntil(this.unSubscribe))
      .subscribe(async(value: string) => {
        this.unSubscribe.next();
        this.unSubscribe.complete();
        const alert = await this.alertCtrl.create({
          header: value[title] ? title : 'Printix App',
          subHeader: value[message],
          buttons: [
          {
            text: value[closeButton],
            role: 'cancel',
            handler: () => {
              observer.next();
              observer.complete();
            }
          }]
        });
        await alert.present();
        const { role } = await alert.onDidDismiss();
      });
    });
  }

  public showTranslatedMessage(message: string, stringular?: any): void {
    this.logger.info('showTranslatedMessage()');
    this.translateService.get('Close')
    .pipe(takeUntil(this.unSubscribe))
    .subscribe((alertOkButtonText: string) => {
      this.translateService.get(message, stringular)
      .pipe(takeUntil(this.unSubscribe))
      .subscribe( async (translatedMessage: string) => {
        this.unSubscribe.next();
        this.unSubscribe.complete();
        let stringulatedMessage: string = this.stringularService.transform(translatedMessage, stringular);
        const alert = await this.alertCtrl.create({
          header: 'Printix App',
          subHeader: stringulatedMessage,
          buttons: [alertOkButtonText]
        });
        await alert.present();
        const { role } = await alert.onDidDismiss();
      });
    });
  }

  public showConfirmDialog(message: string, accept: string, deny: string, title?: string): Observable<boolean> {
    this.logger.info('showConfirmDialog()');
    let confirmOkButtonText = '';
    let confirmCancelButtonText = '';
    let alertTitle = '';
    let alertMessage = '';
    return new Observable<boolean>((observer: Subscriber<boolean>) => {
    this.translateService.get([message, accept, deny, title])
    .pipe(takeUntil(this.unSubscribe))
    .subscribe( async (value: Array<string>) => {
      confirmOkButtonText = value['Yes'];
      confirmCancelButtonText = value['No'];
      alertTitle = value['Capture'];
      alertMessage = value['Cancel'];
      this.unSubscribe.next();
      this.unSubscribe.complete();
      const alert = await this.alertCtrl.create({
          header: title ? alertTitle : 'Printix App',
          cssClass: 'show-confirm-dialog',
          message: alertMessage,
          buttons: [
            {
              text: confirmCancelButtonText,
              role: 'cancel',
              handler: () => {
                observer.next(false);
                observer.complete();
              }
            },
            {
              text: confirmOkButtonText,
              handler: () => {
                observer.next(true);
                observer.complete();
              }
            }
          ]
        });
        await alert.present();
        const { role } = await alert.onDidDismiss();
      });
    });
  }

  // public showPromptDialog(message: string, inputs: Array<{ name: string, placeholder: string }>): Observable<any> {
  //   let confirmOkButtonText: string = '';
  //   let cancelButtonText: string = '';
  //   this.translateService.get('Ok')
  //   .pipe(takeUntil(this.unSubscribe))
  //   .subscribe((value: string) => {
  //     confirmOkButtonText = value;
  //     this.unSubscribe.next();
  //     this.unSubscribe.complete();
  //   });
  //   this.translateService.get('Cancel')
  //   .pipe(takeUntil(this.unSubscribe))
  //   .subscribe((value: string) => {
  //     cancelButtonText = value;
  //     this.unSubscribe.next();
  //     this.unSubscribe.complete();
  //   });
  //   return new Observable<any>((observer: Subscriber<any>) => {
  //     if (inputs.length > 0) {
  //       let alert = this.alertCtrl.create({
  //         header: environment.appTitle,
  //         message: message,
  //         inputs: inputs,
  //         buttons: [
  //           {
  //             text: cancelButtonText,
  //             role: 'cancel',
  //             handler: () => {
  //               observer.next();
  //               observer.complete();
  //             }
  //           },
  //           {
  //             text: confirmOkButtonText,
  //             handler: (data: any) => {
  //               observer.next(data);
  //               observer.complete();
  //             }
  //           }
  //         ]
  //       });
  //       alert.present();
  //     } else {
  //       let error: string = 'DialogService.showPromptDialog: inputs.length is less than 1';
  //       observer.error(error);
  //       observer.complete();
  //     }
  //   });
  // }

  // public showCustomDialog(message: string, buttonNameArray: Array<string>): Observable<number> {
  //   return new Observable<number>((observer: Subscriber<number>) => {
  //     if (buttonNameArray.length !== 2) {
  //       observer.error('DialogService.showCustomDialog: buttonNameArray must have only 2 elements');
  //       observer.complete();
  //     }
  //     let alertOptions: AlertOptions = {
  //       title: environment.appTitle,
  //       message: message,
  //       buttons: []
  //     };
  //     buttonNameArray.forEach((button: string, index: number) => {
  //       let actionSheetButton: { text: string, handler: any, role?: string } = {
  //         text: button,
  //         handler: () => {
  //           observer.next(index);
  //           observer.complete();
  //         }
  //       };
  //       alertOptions.buttons.push(actionSheetButton);
  //     });
  //     let confirmCustom: Alert = this.alertCtrl.create(alertOptions);
  //     confirmCustom.present();
  //   });
  // }

  // public showActionSheetDialog(title: string, buttonArray: Array<{ text: string, destructive: boolean }>): Observable<number> {
  //   return new Observable<number>((observer: Subscriber<number>) => {
  //     let cancelButtonText: string = '';
  //     this.translateService.get('Cancel')
  //   .takeUntil(this.unSubscribe)
  //   .subscribe((value: string) => {
  //       cancelButtonText = value;
  //       this.unSubscribe.complete();
  //     });
  //     let actionSheetOptions: ActionSheetOptions = {
  //       title: title,
  //       buttons: []
  //     };
  //     buttonArray.forEach((button: { text: string, destructive: boolean }, index: number) => {
  //       let buttonReceived: { text: string, destructive: boolean } = button;
  //       let actionSheetButton: { text: string, handler: any, role?: string } = {
  //         text: buttonReceived.text,
  //         handler: () => {
  //           observer.next(index);
  //           observer.complete();
  //         }
  //       };
  //       if (buttonReceived.destructive) {
  //         actionSheetButton.role = 'destructive';
  //       }
  //       actionSheetOptions.buttons.push(actionSheetButton);
  //     });
  //     actionSheetOptions.buttons.push({
  //       text: cancelButtonText,
  //       role: 'cancel',
  //       handler: () => {
  //         observer.next();
  //         observer.complete();
  //       }
  //     });
  //     let actionSheet: ActionSheetController = this.actionSheetCtrl.create(actionSheetOptions);
  //     actionSheet.present();
  //   });
  // }

  public showLoadingSpinnerWithMessage(message: string, fromMethod?: string) {
    this.logger.info('showLoadingSpinnerWithText() ' + 'fromMethod: ' + fromMethod);
    this.spinnerDialog.show(null, message, true, {overlayOpacity: 0.3});
  }

  public async hideLoadingSpinnerDialogProcessImage(method?): Promise<void> {
    this.logger.info('hideLoadingSpinnerDialogProcessImage() from method: ' + method);
    this.spinnerDialog.hide();
  }

  public async showTemporaryLoader() {
    const loading = await this.loadingCtrl.create({
      cssClass: 'print-job-spinner',
      spinner: 'lines'
    });

    loading.present();
  }

  public async tempDismiss() {
    await this.loadingCtrl.dismiss();
  }

  public showLoadingSpinnerDialog(fromMethod?: string): Observable<any> {
    this.logger.info('showLoadingSpinnerDialog() ' + 'fromMethod: ' + fromMethod);
    if (this.isInternetAccessAvailable) {
      return new Observable((observer) => {
        if (this.isCordova) {
          if (this.platform.is('ipad')) {
            this.broadcaster.broadcast('ipad_spinner_show');
            // this.spinnerDialog.show('Loading...', null, true, {overlayOpacity: 0.5, textColorRed: 0.3, textColorGreen: 0.3, textColorBlue: 0.3});
          }
          this.spinnerDialog.show(null, null, true, {overlayOpacity: 0});
          observer.next();
          observer.complete();
        } else {
          // if loader present then dismiss
          if (!this.isShowingLoader) {
            this.isShowingLoader = true;
            // const loading = await this.loadingCtrl.create({
            //   cssClass: 'print-job-spinner',
            //   spinner: 'lines'
            // });
            // loading.present();

            this.loadingIndicator = this.loadingCtrl.create({
              cssClass: 'print-job-spinner',
              showBackdrop: false,
              spinner: 'lines'
            }).then((response) => {
              this.logger.info('PRESENTING_SPINNER - ' + 'fromMethod: ' + fromMethod);
              response.present();
              observer.next();
              observer.complete();
            });
          } else {
            observer.next();
            observer.complete();
          }
        }
      });
    } else {
      console.log('## NO_INTERNET_ACCESS');
    }
  }

  public async hideLoadingSpinnerDialog(method?): Promise<void> {
    this.logger.info('hideLoadingSpinnerDialog()');

    if (this.isCordova) {
      if (this.platform.is('ipad')) {
        this.broadcaster.broadcast('ipad_spinner_hide');
      }
      this.spinnerDialog.hide();
    } else {
      // Instead of directly closing the loader like below line
      // return await this.loadingController.dismiss();

      this.checkAndCloseLoader();

      // sometimes there's delay in finding the loader. so check if the loader is closed after one second. if not closed proceed to close again
      setTimeout(() => {
        this.checkAndCloseLoader(true)
        // setTimeout(() => {
        //   this.checkAndCloseLoader(true)
        // }, 500);
      }, 1000);
    }
  }

  private async checkAndCloseLoader(hadTimeOut?) {
    // Use getTop function to find the loader and dismiss only if loader is present.
    const loader = await this.loadingCtrl.getTop();
    // if loader present then dismiss

    if (loader) {
      await this.loadingCtrl.dismiss().then(() => {});
    } else {
      this.logger.info(' - No loadingIndicator to hide');
    }
    this.isShowingLoader = false;
  }

  // public hideSplashScreen(): void {
  //   this.logger.info('hideSplashScreen()');
  //   this.hideLoadingSpinnerDialog();
  //   // this.splashScreen.hide();
  //   this.platform.ready().then(() => {
  //   });
  // }

  // public showSplashScreen(origin?: string): void {
  //   this.logger.info('showSplashScreen()');
  //   // this.splashScreen.show();
  //   this.showLoadingSpinnerDialog(origin);
  // }
}
