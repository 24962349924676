<ion-header title="{{currentTitle}}">
  <ion-toolbar>
<!--    <i class="icon-logo-menu-mono size220 cl-black" slot="start" title="" [routerDirection]="back" [routerLink]="allowRouterLink() ? ['/print-jobs'] : undefined"></i>-->
    <i class="navbar-brand-logo cl-black" [ngClass]="{'isIos' : isIos, 'isAndroid' : isAndroid}" slot="start" title="" [routerDirection]="back" [routerLink]="allowRouterLink() ? ['/print-jobs'] : undefined"></i>
    <span class="title-text" [ngClass]="{'isIos' : isIos, 'isAndroid' : isAndroid}">Printix</span>
    <ion-title class="title-container" title="{{currentTitle}}"  mode="ios" *ngIf="currentTitle">{{currentTitle | translate}}</ion-title>
    <ion-menu-button slot="end" *ngIf="menuToggleRight" title="menu">
      <i class="icon-menu-toggle cl-grey" [ngClass]="{'isIos' : isIos}"></i>
    </ion-menu-button>
  </ion-toolbar>
</ion-header>
