import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnInit, Output } from '@angular/core';
import { Logger, LoggingService } from 'ionic-logging-service';
import { Observable, Subscriber } from 'rxjs';
import { delay, finalize, retryWhen, take, tap } from 'rxjs/operators';
import { ErrorService } from '../error/error.service';
import { Broadcaster } from '../events/broadcaster.class';
import { IntuneMamService } from '../intune-mam/intune-mam.service';
import { LanguageService } from '../language/language.service';
import { IPrinter } from '../printer/models/printer.model';
import { IQueue } from '../printer/models/queue.model';
import { ITenant } from '../tenant/models/tenant.model';
import { IUser } from './models/user.model';

@Injectable({
  providedIn: 'root'
})

export class UserService implements OnInit {

  // @Output() user: IUser;
  public user: IUser = null;
  private userRaw: any = null;
  private httpErrorResponse: HttpErrorResponse;
  private logger: Logger;

  constructor(
    private broadcaster: Broadcaster,
    private errorService: ErrorService,
    private httpClient: HttpClient,
    private languageService: LanguageService,
    private loggingService: LoggingService,
    ) {
      this.logger = loggingService.getLogger("[UserService]");
      const methodName = "ctor";
      this.logger.entry(methodName);
    }

    ngOnInit(): void {
    }

  public getCurrentUser() {
    this.logger.info('getCurrentUser()');
    return this.user;
  }

  private deserializeLinkObjects(user: IUser, links: any): void {
    if (links) {
      if (links['self'] && links['self'].href) {
        user.links.self = links['self'].href;
      }
      if (links['px:printjobs'] && links['px:printjobs'].href) {
        user.links.printJobs = links['px:printjobs'].href;
      }
      if (links['px:idCode'] && links['px:idCode'].href) {
        user.links.idCode = links['px:idCode'].href;
      }
      if (links['px:pincode'] && links['px:pincode'].href) {
        user.links.pinCode = links['px:pincode'].href;
      }
      if (links['px:cards'] && links['px:cards'].href) {
        user.links.cards = links['px:cards'].href;
      }
      if (links['px:workflows'] && links['px:workflows'].href) {
        user.links.workflows = links['px:workflows'].href;
      }
      if (links['px:releaseQueues'] && links['px:releaseQueues'].href) {
        user.links.releaseQueues = links['px:releaseQueues'].href;
      }
    }
  }

  private deserializeEmbeddedObjects(user: IUser, embedded: any): void {
    if (embedded) {
      user.embedded.authentication_details.source = embedded.authentication_details.source ? embedded.authentication_details.source : null;
      user.embedded.permissions.permissions = embedded.permissions.permissions ? embedded.permissions.permissions : null;
      user.embedded.permissions.role = embedded.permissions.role ? embedded.permissions.role : null;
      user.embedded.authentication_details.source = embedded.authentication_details.source ? embedded.authentication_details.source : null;
      user.embedded.authentication_details.principal = embedded.authentication_details.principal ? embedded.authentication_details.principal : null;
      user.embedded.user.links.self.href = embedded.user._links.self.href ? embedded.user._links.self.href : null;
      user.embedded.user.email = embedded.user.email ? embedded.user.email : null;
      user.embedded.user.name = embedded.user.name ? embedded.user.name : null;
      user.embedded.user.language = embedded.user.language ? embedded.user.language : null;
    }
  }

  private deserializeUserAppSettings(user: IUser, userAppSettings: any): void {
    if (userAppSettings) {
      user.userAppSettings.lastUsedPrinter = userAppSettings.lastUsedPrinter ? userAppSettings.lastUsedPrinter : null;
      user.userAppSettings.lastUsedQueue = userAppSettings.lastUsedQueue ? userAppSettings.lastUsedQueue : null;
      user.userAppSettings.lastUsedNetwork = userAppSettings.lastUsedNetwork ? userAppSettings.lastUsedNetwork : null;
      user.userAppSettings.lastUsedTenant = userAppSettings.lastUsedTenant ? userAppSettings.lastUsedTenant : null;
      user.userAppSettings.changeLogReviewDate = userAppSettings.changeLogReviewDate ? userAppSettings.changeLogReviewDate : null;
      user.userAppSettings.pinnedPrinterQueues = userAppSettings.pinnedPrinterQueues ? userAppSettings.pinnedPrinterQueues : null;
      user.userAppSettings.pinnedPrinters = userAppSettings.pinnedPrinters ? userAppSettings.pinnedPrinters : null;
      user.userAppSettings.securePrintMethods = userAppSettings.securePrintMethods ? userAppSettings.securePrintMethods : null;
      user.userAppSettings.serviceMenuEnabled = userAppSettings.serviceMenuEnabled ? userAppSettings.serviceMenuEnabled : null;
    }
  }

  private deserializeUser(input: any, skipObservers: boolean): IUser {
    let user: IUser = {
      links: {
        self: null,
        printJobs: null,
        idCode: null,
        pinCode: null,
        cards: null,
        releaseQueues: null,
        workflows: null
      },
      embedded: {
        permissions: {
          permissions: null,
          role: null
        },
        authentication_details: {
          source: null,
          principal: null
        },
        user: {
          links: {
            self: {
              href: null
            }
          },
          email: null,
          name: null,
          language: null
        }
      },
      groups: input._embedded.groups ? this.formatGroupsArray(input._embedded.groups) : [],
      userAppSettings: {
        lastUsedPrinter: null,
        lastUsedQueue: null,
        lastUsedNetwork: null,
        lastUsedTenant: null,
        changeLogReviewDate: null,
        pinnedPrinterQueues: null,
        pinnedPrinters: null,
        securePrintMethods: null,
        serviceMenuEnabled: null
      },
      name: input._embedded.user.name ? input._embedded.user.name : null,
      inactivityAutoDelete: input.inactivityAutoDelete ? input.inactivityAutoDelete : false,
      api: {
        getIdCode: (user: IUser) => {
          return this.getIdCode(user);
        },
        setIdCode: (tenant: ITenant) => {
          return this.setIdCode(tenant);
        },
        deleteIdCode: (idCode:any) => {
          return this.deleteIdCode(idCode);
        },
        getPinCode: (user: IUser) => {
          return this.getPinCode(user);
        },
        setPinCode: (user: IUser, pinCode: any) => {
          return this.setPinCode(user, pinCode);
        },
        deletePinCode: (user: IUser) => {
          return this.deletePinCode(user);
        },
        getCards: (user: IUser) => {
          return this.getCards(user);
        },
        deleteCard: (card: any) => {
          return this.deleteCard(card);
        },
        registerCard: (user: IUser, cardCode: any) => {
          return this.registerCard(user, cardCode);
        }
      }
    };
    if (input._links) {this.deserializeLinkObjects(user, input._links);}
    if (input._embedded) {this.deserializeEmbeddedObjects(user, input._embedded);}
    if (input.userAppSettings) {this.deserializeUserAppSettings(user, input.userAppSettings);}
    if (!skipObservers) {
      this.languageService.changeLanguage(user.embedded.user.language);
    }
    return user;
  }

  public formatGroupsArray (groupsArrayRaw): Array<string> {
    let groupsArray: Array<string> = [];
    if (groupsArrayRaw) {
      for (let i = 0; i < groupsArrayRaw.length; i++) {
        groupsArray.push(groupsArrayRaw[i]._links.self.href);
      }
    }

    return groupsArray;
  }

  public refreshCurrentUser(currentUserUrl: string): Observable<any> {
    this.logger.info('refreshCurrentUser()');
    return new Observable((observer) => {
      this.httpClient.get<any>(currentUserUrl)
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(1),
    // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('refreshCurrentUser() httpErrorResponse === ' + this.httpErrorResponse.status);
            observer.error(this.httpErrorResponse);
            observer.complete();
          } else {
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'GET', '[UserService] refreshCurrentUser()');
            observer.error(this.httpErrorResponse);
            observer.complete();
          }
        })
      )))
      .subscribe((res: any) => {
        this.logger.info('currentUserEmail: ' + res._embedded.user.email);
        this.logger.info('currentUserID: ' + res._embedded.user._links.self.href);
        this.userRaw = res;
        this.user = this.deserializeUser(res, false);
        this.broadcaster.broadcast('refreshedUser', this.user);
        observer.next(this.user);
        observer.complete();
      });
    });
  }

  public saveLanguageChange(itemToSave: string, user): Observable<void> {
    this.logger.info('saveLanguageChange()');
    let objectToSend: any = {
      op: 'replace',
      path: '/language',
      value: itemToSave
    };

    return new Observable((observer) => {
      this.httpClient.patch(user.embedded.user.links.self.href, [objectToSend])
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(3),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('saveLanguageChange() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else {
            this.logger.info('saveLanguageChange() PATCH ERROR - Object to send: ' + JSON.stringify(objectToSend));
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'PATCH', '[UserService] saveLanguageChange()');
          }
          observer.error(this.httpErrorResponse);
          observer.complete();
        })
      )))
      .subscribe((response: any) => {
        // this.user = this.deserializeUser(response, false);
        observer.next();
        observer.complete();
      });
    });
  }

  public resetUser(): void {
    this.logger.info('resetUser()');
    this.broadcaster.broadcast('loggedOutUser');
    this.user = null;
  }

  public changePrinterQueuePinStatus(printQueueUrl: string, isPinned: boolean): Observable<void> {
    this.logger.info('changePrinterQueuePinStatus() ' + 'isPinned ' + isPinned);

    if (!isPinned) {
     return this.removePinnedQueue(printQueueUrl);
    } else {
      return this.addPinnedQueue(printQueueUrl);
    }
  }

  private addPinnedQueue(printQueueUrl: string): Observable<void> {
    this.logger.info('addPinnedQueue()');
    let objectToSend: any = {
      op: 'add',
      path: '/userAppSettings/pinnedPrinterQueues/-',
      value: printQueueUrl
    };

    if (this.userRaw && !this.userRaw.userAppSettings) {
      objectToSend.path = '/userAppSettings';
      objectToSend.value = {
        pinnedPrinterQueues: [printQueueUrl]
      };
    } else if (this.userRaw && !this.userRaw.userAppSettings.pinnedPrinterQueues) {
      objectToSend.path = '/userAppSettings/pinnedPrinterQueues';
      objectToSend.value = [printQueueUrl];
    }

    return new Observable((observer) => {
      this.httpClient.patch(this.user.links.self, [objectToSend])
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(3),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('addPinnedQueue() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else {
            this.logger.info('addPinnedQueue() PATCH ERROR - Object to send: ' + JSON.stringify(objectToSend));
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'PATCH', '[UserService] addPinnedQueue()');
          }
          observer.error(this.httpErrorResponse);
          observer.complete();
        })
      )))
      .subscribe((response) => {
        if (this.userRaw && !this.userRaw.userAppSettings) {
          this.userRaw['userAppSettings'] = {};
          if (this.userRaw && !this.userRaw.userAppSettings.pinnedPrinterQueues) {
            this.userRaw.userAppSettings['pinnedPrinterQueues'] = null;
          }
        }
        this.userRaw.userAppSettings.pinnedPrinterQueues = response['userAppSettings'].pinnedPrinterQueues;
        this.user.userAppSettings.pinnedPrinterQueues = response['userAppSettings'].pinnedPrinterQueues;
        observer.next();
        observer.complete();
      });
    });
  }

  private removePinnedQueue(printQueueUrl: string): Observable<void> {
    this.logger.info('removePinnedQueue(): ' + printQueueUrl);
    let objectToSend: any = {};

    let indexOfPrinter = this.userRaw.userAppSettings && this.userRaw.userAppSettings.pinnedPrinterQueues ? this.userRaw.userAppSettings.pinnedPrinterQueues.indexOf(printQueueUrl) : -1;
    if (indexOfPrinter > -1) {
      objectToSend.op = 'remove';
      objectToSend.path = '/userAppSettings/pinnedPrinterQueues/' + indexOfPrinter;
      delete objectToSend.value;

      return new Observable((observer) => {
        this.httpClient.patch(this.user.links.self, [objectToSend])
        .pipe(retryWhen(error => error.pipe(
          delay(1000),
          take(3),
          // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
          tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
          finalize(() => {
            if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
              this.logger.info('removePinnedQueue() httpErrorResponse === ' + this.httpErrorResponse.status);
            } else {
              this.logger.info('removePinnedQueue() PATCH ERROR - Object to send: ' + JSON.stringify(objectToSend));
              this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'PATCH', '[UserService] removePinnedQueue()');
            }
            observer.error(this.httpErrorResponse);
            observer.complete();
          })
        )))
        .subscribe((response) => {
          if (this.userRaw && !this.userRaw.userAppSettings) {
            this.userRaw['userAppSettings'] = {};
            if (this.userRaw && !this.userRaw.userAppSettings.pinnedPrinterQueues) {
              this.userRaw.userAppSettings['pinnedPrinterQueues'] = null;
            }
          }
          this.userRaw.userAppSettings.pinnedPrinterQueues = response['userAppSettings'].pinnedPrinterQueues;
          this.user.userAppSettings.pinnedPrinterQueues = response['userAppSettings'].pinnedPrinterQueues;
          observer.next();
          observer.complete();
        });
      });
    } else {
      this.logger.info('Queue is not saved in userAppSettings');
      return;
    }
  }

  public setAvailablePinnedPrinterQueues(queueUrls: Array<string>): Observable<void> {
    this.logger.info('setAvailablePinnedPrinterQueues()');

    let objectToPatch: Array<any> = [];

    objectToPatch.push(
      {
        'op': 'remove',
        'path': '/userAppSettings/pinnedPrinterQueues'
      }
    );

    if (queueUrls.length > 0) {
      objectToPatch.push(
        {
          'op': 'add',
          'path': '/userAppSettings/pinnedPrinterQueues/',
          'value': []
        }
      );
      queueUrls.forEach((url) => {
        objectToPatch.push(
          {
            'op': 'add',
            'path': '/userAppSettings/pinnedPrinterQueues/-',
            'value': url
          }
        );
      });
    }

    return new Observable((observer) => {
      this.httpClient.patch(this.user.links.self, objectToPatch)
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(3),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('setAvailablePinnedPrinterQueues() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else {
            this.logger.info('setAvailablePinnedPrinterQueues() PATCH ERROR - Object to send: ' + JSON.stringify(objectToPatch));
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'PATCH', '[UserService] setAvailablePinnedPrinterQueues()');
          }
          observer.error(this.httpErrorResponse);
          observer.complete();
        })
      )))
      .subscribe((response) => {
        if (this.userRaw && !this.userRaw.userAppSettings) {
          this.userRaw['userAppSettings'] = {};
          if (this.userRaw && !this.userRaw.userAppSettings.pinnedPrinterQueues) {
            this.userRaw.userAppSettings['pinnedPrinterQueues'] = null;
          }
        }
        this.userRaw.userAppSettings.pinnedPrinterQueues = response['userAppSettings'].pinnedPrinterQueues;
        this.user.userAppSettings.pinnedPrinterQueues = response['userAppSettings'].pinnedPrinterQueues;
        observer.next();
        observer.complete();
      });
    });
  }

  public changePrinterPinStatus(printerUrls: Array<string>, isPinned: boolean): Observable<void> {
    this.logger.info('changePrinterPinStatus() to NO pinned printers');

    let objectToPatch: Array<any> = [];

    printerUrls.forEach(url => {
      objectToPatch.push(
        {
          "op": "remove",
          path: '/userAppSettings/pinnedPrinters/' + 0
        }
      );
    });

    return new Observable((observer) => {
      this.httpClient.patch(this.user.links.self, [objectToPatch])
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(3),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('changePrinterPinStatus() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else {
            this.logger.info('changePrinterPinStatus() PATCH ERROR - Object to send: ' + JSON.stringify(objectToPatch));
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'PATCH', '[UserService] changePrinterPinStatus()');
          }
          observer.error(this.httpErrorResponse);
          observer.complete();
        })
      )))
      .subscribe((response) => {
        if (this.userRaw && !this.userRaw.userAppSettings) {
          this.userRaw['userAppSettings'] = {};
          if (this.userRaw && !this.userRaw.userAppSettings.pinnedPrinters) {
            this.userRaw.userAppSettings['pinnedPrinters'] = null;
          }
        }
        this.userRaw.userAppSettings.pinnedPrinters = response['userAppSettings'].pinnedPrinters;
        this.user.userAppSettings.pinnedPrinters = response['userAppSettings'].pinnedPrinters;
        observer.next();
        observer.complete();
      });
    });
  }

  public setLastUsedPrinter = function (printer: IPrinter): Observable<void> {
    this.logger.info('setLastUsedPrinter()');
    let objectToSend: any = {
      op: 'replace',
      path: '/userAppSettings/lastUsedPrinter',
      value: printer.links.self,
    };
    if (this.userRaw && !this.userRaw.userAppSettings) {
      objectToSend = {
        op: 'add',
        path: '/userAppSettings',
        value: {
          lastUsedPrinter: printer.links.self
        }
      };
    } else {
      if (this.userRaw && !this.userRaw.userAppSettings.lastUsedPrinter) {
        objectToSend.op = 'add';
      }
    }

      return new Observable((observer) => {
      this.httpClient.patch(this.user.links.self, [objectToSend])
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(3),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('setLastUsedPrinter() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else {
            this.logger.info('setLastUsedPrinter() PATCH ERROR - Object to send: ' + JSON.stringify(objectToSend));
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'PATCH', '[UserService] setLastUsedPrinter()');
          }
          observer.error(this.httpErrorResponse);
          observer.complete();
        })
      )))
      .subscribe((response) => {
        if (this.userRaw && !this.userRaw.userAppSettings) {
          this.userRaw['userAppSettings'] = {};
          if (this.userRaw && !this.userRaw.userAppSettings.lastUsedPrinter) {
            this.userRaw.userAppSettings['lastUsedPrinter'] = null;
          }
        }
        this.userRaw.userAppSettings.lastUsedPrinter = response['userAppSettings'].lastUsedPrinter;
        this.user.userAppSettings.lastUsedPrinter = response['userAppSettings'].lastUsedPrinter;
        observer.next();
        observer.complete();
      });
    });
  };

  public setLastUsedQueue = function (queue: IQueue): Observable<void> {
    this.logger.info('setLastUsedQueue()');
    let objectToSend: any = {
      op: 'replace',
      path: '/userAppSettings/lastUsedQueue',
      value: queue.links.self,
    };
    if (this.userRaw && !this.userRaw.userAppSettings) {
      objectToSend = {
        op: 'add',
        path: '/userAppSettings',
        value: {
          lastUsedQueue: queue.links.self
        }
      };
    } else {
      if (this.userRaw && !this.userRaw.userAppSettings.lastUsedQueue) {
        objectToSend.op = 'add';
      }
    }
    return new Observable((observer) => {
      this.httpClient.patch(this.user.links.self, [objectToSend])
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(3),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('setLastUsedQueue() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else {
            this.logger.info('setLastUsedQueue() PATCH ERROR - Object to send: ' + JSON.stringify(objectToSend));
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'PATCH', '[UserService] setLastUsedQueue()');
          }
          observer.error(this.httpErrorResponse);
          observer.complete();
        })
      )))
      .subscribe((response) => {
        if (this.userRaw && !this.userRaw.userAppSettings) {
          this.userRaw['userAppSettings'] = {};
          if (this.userRaw && !this.userRaw.userAppSettings.lastUsedQueue) {
            this.userRaw.userAppSettings['lastUsedQueue'] = null;
          }
        }

        this.userRaw.userAppSettings.lastUsedQueue = response['userAppSettings'].lastUsedQueue;
        this.user.userAppSettings.lastUsedQueue = response['userAppSettings'].lastUsedQueue;
        observer.next();
        observer.complete();
      });
    });
  };

  public setSecurePrintMethods = function (securePrintMethods: Array<string>): Observable<void> {
    this.logger.info('setSecurePrintMethods()');
    let objectToPatch: any = {};
    if (this.userRaw && !this.userRaw.userAppSettings) {
      objectToPatch = [
        {
          op: 'add',
          path: '/userAppSettings',
          value: {
            securePrintMethods: securePrintMethods
          }
        }
      ];
    } else {
      if (this.userRaw && !this.userRaw.userAppSettings.securePrintMethods) {
        objectToPatch = [
          {
            op: 'add',
            path: '/userAppSettings/securePrintMethods',
            value: securePrintMethods
          }
        ];
      } else {
        objectToPatch = [
          {
            op: 'replace',
            path: '/userAppSettings/securePrintMethods',
            value: securePrintMethods
          }
        ];
      }
    }

    return new Observable((observer) => {
      this.httpClient.patch(this.user && this.user.links.self, objectToPatch)
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(3),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('setSecurePrintMethods() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else {
            this.logger.info('setSecurePrintMethods() PATCH ERROR - Object to send: ' + JSON.stringify(objectToPatch));
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'PATCH', '[UserService] setSecurePrintMethods()');
          }
          observer.error(this.httpErrorResponse);
          observer.complete();
        })
      )))
      .subscribe((response) => {
        if (this.userRaw && !this.userRaw.userAppSettings) {
          this.userRaw['userAppSettings'] = {};
          if (this.userRaw && !this.userRaw.userAppSettings.securePrintMethods) {
            this.userRaw.userAppSettings['securePrintMethods'] = null;
          }
        }

        this.userRaw.userAppSettings.securePrintMethods = response['userAppSettings'].securePrintMethods;
        this.user.userAppSettings.securePrintMethods = response['userAppSettings'].securePrintMethods;
        observer.next();
        observer.complete();
      });
    });
  };

  public removeLastUsedPrinterAndQueue(): Observable<void> {
    this.logger.info('removeLastPrinterAndQueue()');
    let objectToSend: Array<any> = [];

    objectToSend.push(
      {
        op: 'remove',
        path: '/userAppSettings/lastUsedPrinter'
      },
      {
        op: 'remove',
        path: '/userAppSettings/lastUsedQueue'
      }
    );

    return new Observable((observer) => {
      this.httpClient.patch(this.user.links.self, [objectToSend])
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(3),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('removeLastPrinterAndQueue() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else {
            this.logger.info('removeLastPrinterAndQueue() PATCH ERROR - Object to send: ' + JSON.stringify(objectToSend));
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'PATCH', '[UserService] removeLastPrinterAndQueue()');
          }
          observer.error(this.httpErrorResponse);
          observer.complete();
        })
      )))
      .subscribe((response) => {
        if (this.userRaw && !this.userRaw.userAppSettings) {
          this.userRaw['userAppSettings'] = {};
          if (this.userRaw && !this.userRaw.userAppSettings.lastUsedPrinter) {
            this.userRaw.userAppSettings['lastUsedPrinter'] = null;
          }
        }
        this.userRaw.userAppSettings.lastUsedPrinter = response['userAppSettings'].lastUsedPrinter;
        this.user.userAppSettings.lastUsedPrinter = response['userAppSettings'].lastUsedPrinter;
        observer.next();
        observer.complete();
      });
    });
  }

  public removeAllPinnedQueues(): Observable<void> {
    this.logger.info('removeAllPinnedQueues()');
    let objectToSend: Array<any> = [];

    objectToSend.push(
      {
        op: 'remove',
        path: '/userAppSettings/pinnedPrinterQueues'
      }
    );


    return new Observable((observer) => {
      this.httpClient.patch(this.user.links.self, [objectToSend])
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(3),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('removeAllPinnedQueues() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else {
            this.logger.info('removeAllPinnedQueues() PATCH ERROR - Object to send: ' + JSON.stringify(objectToSend));
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'PATCH', '[UserService] removeAllPinnedQueues()');
          }
          observer.error(this.httpErrorResponse);
          observer.complete();
        })
      )))
      .subscribe((response) => {
        if (this.userRaw && !this.userRaw.userAppSettings) {
          this.userRaw['userAppSettings'] = {};
          if (this.userRaw && !this.userRaw.userAppSettings.pinnedPrinterQueues) {
            this.userRaw.userAppSettings['pinnedPrinterQueues'] = null;
          }
        }
        this.userRaw.userAppSettings.pinnedPrinterQueues = response['userAppSettings'].pinnedPrinterQueues;
        this.user.userAppSettings.pinnedPrinterQueues = response['userAppSettings'].pinnedPrinterQueues;
        observer.next();
        observer.complete();
      });
    });
  }

  public getIdCode(user: IUser): Observable<any> {
    this.logger.info('getIdCode()');
    let idCodeUrl = user.links.idCode;

    return new Observable((observer) => {
      this.httpClient.get<any>(idCodeUrl)
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(1),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('getIdCode() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else if (this.httpErrorResponse.status === 404 || this.httpErrorResponse.status === 410) {
            this.logger.info('getIdCode() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else {
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'GET', '[UserService] getIdCode()');
          }
          observer.error(this.httpErrorResponse);
          observer.complete();
        })
      )))
      .subscribe((response: any) => {
        observer.next(response);
        observer.complete();
      });
    });
  }

  public setIdCode(tenant: ITenant): Observable<any> {
    this.logger.info('setIdCode()');
    let idCodesUrl = tenant.links.idCodes;

    return new Observable((observer) => {
      this.httpClient.post<any>(idCodesUrl, {})
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(1),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('setIdCode() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else {
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'POST', '[UserService] setIdCode()');
          }
          observer.error(this.httpErrorResponse);
          observer.complete();
        })
      )))
      .subscribe((response: any) => {
        observer.next(response);
        observer.complete();
      });
    });
  }

  public deleteIdCode(idCodeResource:any): Observable<any> {
    this.logger.info('deleteIdCode()');

     return new Observable((observer) => {
      this.httpClient.delete<any>(idCodeResource._links.self.href)
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(1),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('deleteIdCode() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else {
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'DELETE', '[UserService] deleteIdCode()');
          }
          observer.error(this.httpErrorResponse);
          observer.complete();
        })
      )))
      .subscribe((response: any) => {
        observer.next(response);
        observer.complete();
      });
    });
  }

  public getPinCode(user: IUser): Observable<any> {
    this.logger.info('getPinCode()');
    let pinCodeUrl = user.links.pinCode;
    return new Observable((observer) => {
      this.httpClient.get<any>(pinCodeUrl)
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(1),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('getPinCode() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else if (this.httpErrorResponse.status === 404 || this.httpErrorResponse.status === 410) {
            this.logger.info('getPinCode() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else {
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'GET', '[UserService] getPinCode()');
          }
          observer.error(this.httpErrorResponse);
          observer.complete();
        })
      )))
      .subscribe((response: any) => {
        observer.next(response);
        observer.complete();
      });
    });
  }

  public setPinCode(user: IUser, pinCode): Observable<any> {
    this.logger.info('setPinCode()');
    let pinCodeUrl = user.links.pinCode;
    let postObject = {
      pincode: pinCode
    };
    return new Observable((observer) => {
      this.httpClient.post<any>(pinCodeUrl, postObject)
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(1),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('setPinCode() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else if (this.httpErrorResponse.status !== 409) {
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'POST', '[UserService] setPinCode()');
          }
          observer.error(this.httpErrorResponse);
          observer.complete();
        })
      )))
      .subscribe((response: any) => {
        observer.next(response);
        observer.complete();
      });
    });
  }

  public deletePinCode(pinCodeResource:any): Observable<any> {
    this.logger.info('deletePinCode()');
    return new Observable((observer) => {
      this.httpClient.delete<any>(pinCodeResource._links.self.href)
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(1),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('deletePinCode() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else {
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'DELETE', '[UserService] deletePinCode()');
          }
          observer.error(this.httpErrorResponse);
          observer.complete();
        })
      )))
      .subscribe((response: any) => {
        observer.next(response);
        observer.complete();
      });
    });
  }


  public getCards(user: IUser): Observable<any> {
    this.logger.info('getCards()');
    let cardsUrl = user.links.cards;

    return new Observable((observer) => {
      this.httpClient.get<any>(cardsUrl)
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(1),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('getCards() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else {
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'GET', '[UserService] getCards()');
          }
          observer.error(this.httpErrorResponse);
          observer.complete();
        })
      )))
      .subscribe((response: any) => {
        observer.next(response);
        observer.complete();
      });
    });
  }

  public deleteCard (card: any): Observable<any> {
    this.logger.info('deleteCard()');
    let cardUrl = card._links.self.href;
    return new Observable((observer) => {
      this.httpClient.delete<any>(cardUrl)
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(1),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('deleteCard() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else {
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'DELETE', '[UserService] deleteCard()');
          }
          observer.error(this.httpErrorResponse);
          observer.complete();
        })
      )))
      .subscribe((response) => {
        observer.next(response);
        observer.complete();
      });
    });
  }

  public registerCard(user: IUser, cardCode: any): Observable<any> {
    this.logger.info('registerCard()');
    let cardUrl = user.links.cards;
    let postObject = {
      registrationCode: cardCode
    };
    return new Observable((observer) => {
      this.httpClient.post<any>(cardUrl, postObject)
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(1),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('registerCard() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else if (this.httpErrorResponse.status !== 404) {
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'POST', '[UserService] registerCard()');
          }
          observer.error(this.httpErrorResponse);
          observer.complete();
        })
      )))
      .subscribe((response: any) => {
        observer.next(response);
        observer.complete();
      });
    });
  }
}